var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"5e5c09e29687ee234951ad72d963a852fbe4251f"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const hostname = window.location.hostname;

let env = 'production-eu';
if (hostname.startsWith('qa')) {
  env = 'qa';
}
if (hostname.startsWith('staging')) {
  env = 'staging';
}
if (hostname === 'localhost') {
  env = 'dev';
}

Sentry.init({
  dsn: process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: env === 'production' ? 0.05 : 0.1,
  enabled: hostname !== 'localhost' && !hostname.startsWith('review-'),
  release: process.env.NEXT_PUBLIC_GIT_HASH,
  environment: env,
});
